* {
  margin: 0;
  font-family: "IBM Plex Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.d-flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.password-toggle-icon {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  margin-top: 35px;
  margin-right: 5px;
}

.password-container {
  position: relative;

}

.dashboard-card {
  transition: 0.2s ease-in-out;
  width: 18rem;
  margin: 5px;
  cursor: pointer;
}

.dashboard-card:hover {
  box-shadow: 0 6px 6px 0 #51e9bb;
}