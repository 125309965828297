// 
// components-demo.scss
//

// Demo Only
.button-list {
    margin-left: -8px;
    margin-bottom: -12px;

    .btn {
        margin-bottom: 12px;
        margin-left: 8px;
    }
}

// Grid
.grid-structure {
    .grid-container {
        background-color: $table-striped-bg;
        margin-bottom: 10px;
        font-size: 0.8rem;
        font-weight: $font-weight-semibold;
        padding: 10px 20px;
    }
}

// Icon demo ( Demo only )
.icons-list-demo {
    div.col-sm-6 {
        cursor: pointer;
        line-height: 45px;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        overflow: hidden;

        p {
            margin-bottom: 0;
            line-height: inherit;
        }
    }

    i {
        text-align: center;
        vertical-align: middle;
        font-size: 22px;
        width: 50px;
        height: 50px;
        line-height: 50px;
        margin-right: 12px;
        color: $text-muted;
        border-radius: 3px;
        display: inline-block;
        transition: all 0.2s;
    }

    .col-md-4 {
        border-radius: 3px;
        background-clip: padding-box;
        margin-bottom: 10px;

        &:hover,
        &:hover i {
            color: $primary;
        }
    }
}

.icons-list {
    .icon-item {
        padding: 15px 0;
        color: $gray-600;

        svg {
            margin-right: 10px;
        }

        span {
            line-height: 30px;
            display: inline-block;
            vertical-align: middle;
        }
    }
}


// Scrollspy

.scrollspy-example {
    position: relative;
    height: 200px;
    margin-top: .5rem;
    overflow: auto;
}

table .badge {
    padding: 0.6em 1em !important;
    font-size: 90% !important;
    font-weight: 500 !important;
}

.onleft {
    border-radius: 0.25rem;
    position: relative;
    padding: 24px;
    color: #fff !important;
    margin-bottom: 24px;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/eficensit-test-8b2e9.appspot.com/o/MicrosoftTeams-image.png?alt=media&token=3876c3d9-d6dd-42d8-88c5-ee147532d5a9');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.onleft:before {
    // content: url('../../../images/circle-top.svg');
    position: absolute;
    right: 0;
    top: 0;
}

.onleft:after {
    // content: url('../../../images/circle-bottom.svg');
    position: absolute;
    left: 0;
    bottom: -4px;
}

.stateicon {
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    font-size: 20px;
    color: #fff;
    background: rgba(153, 224, 202, 0.3);
    width: 52px;
    height: 52px;
    border-radius: 100px;
    margin-right: 16px;
}

.idesc p {
    margin-bottom: 3px;
    color: rgba(235, 236, 240, 0.9);
}

.idesc h3 {
    font-size: 21px;
    color: #fff;
}

.stateborder {
    border-bottom: 1px solid #C1C7D0;
}

.oscard {
    background: rgba(153, 224, 202, 0.4);
    padding: 26px 22px;
    border-radius: .25rem;
    position: relative;
    z-index: 1;
}

.oscard h3 {
    font-size: 20px;
    color: #fff;
    font-weight: 500;
}

.btn-custom {
    background: #2C9273;
    text-align: center;
    width: 100%;
    padding: 12px;
    font-size: 16px;
    position: relative;
    z-index: 1;
}

.btn-custom.btn:hover {
    opacity: .9;
    color: #fff !important;
}

tr:last-child td {
    border-bottom: 0px;
}